<template>
    <div id="item-two-steps-select">
        <multiselect
            v-if="!type"
            placeholder="Selecionar tipo..."
            v-model="type"
            :options="types"
            :showLabels="false"
            :internalSearch="false"
            :searchable="false"
            class="with-border"
            @select="onSelectType"
        >
            <template slot="caret">
                <div v-if="!loading" class="chevron">
                    <ChevronDown />
                </div>
                <div v-else class="loading-icon">
                    <b-spinner small type="grow" variant="primary"></b-spinner>
                </div>
            </template>
            <template slot="option" slot-scope="{ option }">
                {{ CONSTANTS.parseTypeToLabel(option) }}
            </template>
            <template slot="noOptions">
                Nenhuma opção
            </template>
            <template slot="noResult">
                Nenhum resultado
            </template>
        </multiselect>


        <div v-else class="custom-input-prefix">
            <div
                class="profile-prefix"
                :style="disableTypeSelection && { cursor: 'default' }"
                :aria-disabled="disableTypeSelection"
                @click="!disableTypeSelection && clearSelectors()"
            >
                Tipo: {{ CONSTANTS.parseTypeToLabel(type) }}
            </div>
            <multiselect
                id="item-select"
                ref="itemMultiselect"
                label="name"
                track-by="id"
                v-model="items"
                :options="itemOptions"
                :multiple="true"
                :showLabels="false"
                :internal-search="true"
                :searchable="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Filtrar item..."
                class="secondary-selector"
            >
                <template slot="caret">
                    <div  class="icon-loading">
                        <b-spinner v-if="loading" small type="grow" variant="primary"></b-spinner>
                        <CloseSmall 
                            v-if="!loading && (items.length || type)" 
                            v-b-tooltip.hover title="Limpar seleção"
                            @click="clearSelection" 
                        />
                    </div>
                </template>
                <template #selection="{ values }">
                    <div class="selection">
                        {{ values.length }} itens selecionados
                    </div>
                </template>
                <template #option="{ option }">
                    <div class="option">
                        <Check 
                            :value="items.some(item => item.id === option.id)"
                            :label="`${option.code ? option.code + ' - ' : ''}${option.name}`"
                        />
                        <div class="mask"></div>
                    </div>
                </template>
                <template slot="noOptions">Realize uma busca para obter resultados</template>
                <template slot="noResult">Nenhum resultado encontrado</template>
            </multiselect>
        </div>
    </div>
</template>
<script>
import api from '@items/api'
import { debounce } from 'lodash';
import { getCurrentClinic } from '@/utils/localStorageManager'
import * as CONSTANTS from '@items/utils/constants'

export default {
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        CloseSmall: () => import('@/assets/icons/close-small.svg'),
        Check: () => import('@/components/General/Check')
    },
    props: {
        value: Array,
        validated: Boolean,
        disabled: { type: Boolean, default: false },
        ruleItems: {
            type: [Array, null, undefined]
        }
    },
    mounted() {
        this.searchDebounce = debounce(this.searchItemsByType, 300);
    },
    data() {
        return {
            CONSTANTS,
            loading: false,
            clinic: getCurrentClinic(),
            types: CONSTANTS.ITEMS_WITH_HEALTH_PLAN_PROPS,
            disableTypeSelection: false,
            type: null,
            items: [],
            itemOptions: [],
            searchDebounce: Function
        }
    },
    methods: {
        onSelectType() {
            this.searchItemsByType()
        },
        searchItemsByType() {
            this.loading = true;
            api.searchItemsByType(this.clinic.id, this.type)
                .then(({ data }) => {
                // Filtra os itens que não estão presentes em ruleItems
                const selectedIds = this.ruleItems.map(item => item.item_id);
                this.itemOptions = data.filter(item => !selectedIds.includes(item.id));
                })
                .catch(error => this.$toast.error(error.message))
                .finally(() => (this.loading = false));
        },
        clearSelection() {
            this.items = [];
            this.type = null;
        }
    },
    watch: {
        items() {
            this.$emit('select', this.items);
            this.$emit('input', this.items);
        }
    }
}
</script>
<style lang="scss" scoped>

.custom-input-prefix {
    width: 100%;
    display: inline-flex;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    height: 38px;
    border: 1px solid var(--neutral-300);
    padding: 15px 0 15px 16px;
    font-size: 14px;
    white-space: nowrap;
    margin-bottom: 8px;
}
.profile-prefix {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 20%;
    font-weight: 400;
    color: var(--neutral-500);
    cursor: pointer;
    padding-right: 10px;
}
.secondary-selector {
    width: 93%;
}
.selection {
    width: 100%;
    padding-left: 12px;
    padding-right: 35px;
}
.option {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}
.mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.icon-loading {
    display: flex;
    align-items: center;
    width: 24px;
    height:38px;
    padding: 0;
    right: 16px;
    position: absolute;
    z-index: 10;
    svg {
        width: 24px;
        height: 24px;
        fill: var(--blue-500);
    }
}

.create-item {
    position: absolute;
    top: -24px;
    right: 0;
    font-size: 0.8rem;
    font-weight: 700;
    color: var(--blue-500);
    cursor: pointer;
}
</style>